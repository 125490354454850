import React, { useEffect, useState}  from 'react'
import Card from '../../Common/Cards/Card'
import CardCate from '../../Common/Cards/CardCate'
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

function Awards() {

  const { id } = useParams();

  console.log(id);

  
  const [eventData, setEventData] = useState([]);

  // sketon state
  const [loading, setLoading]=useState(true);


  useEffect(() => {
    // Fetch JSON data from your API endpoint
    fetch(`https://hstonline.tech/hstsms/api/v1/programs/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);   
        setEventData(data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  console.log('eventData:', eventData);


  // fetch categories


  const [categoryData, setCategorytData] = useState([]);

  useEffect(() => {
    // Fetch JSON data from your API endpoint
    fetch(`https://hstonline.tech/hstsms/api/v1/categories?program_id=${id}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
       
        setCategorytData(data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data: here', error);
      });
  }, [id]);

  console.log('eventData:', categoryData);



  return (
    <div className='flex flex-col m-auto page'>
      <section className="awardDes flex w-full mt-5 mb-3 pb-5 items-start" style={{borderBottom: '3px solid #0f305f'}}>
  
    {loading ?(
      <Skeleton count={1} height={400} duration={1}/>
    ):(

      <div className="eventImage w-1/2" style={{ height: '300px', backgroundImage: `url(${eventData.image_url})`, backgroundSize: 'cover', backgroundPosition: 'top' }}></div>
    )}

        <div className='desContent w-1/2 text-left px-5'>
          <h1 className='font-bold text-3xl'>{eventData.name}</h1>

          <div className="flex flex-col items-start mb-3 mt-4 text-left w-full">
            <p>Date</p>
            <small className='text-gray-400'>15th January,2024</small>
          </div>

          <div className="flex flex-col items-start mb-3 text-left w-full">
            <p>Description</p>
            <small className='text-gray-400'>MISS GATA
</small>
          </div>

          <div className="flex flex-col items-start mb-3 text-left w-full">
            <p>Organizer</p>
            <small className='text-gray-400'>CROSSMARK MARKETING</small>
          </div>
        </div>
      </section>
      <h1 className='text-xl font-semibold '>Categories</h1>

      {loading ?(
                <Skeleton count={3} height={50} direction='rtl'/>
               ):(
          <section className="flex flex-wrap justify-center w-full my-3 pt-2" style={{borderTop: '3px solid #0f305f'}}>
          
            {categoryData.map(event => (
              <CardCate key={event.id} data={event} />
            ))}

          </section>
      )}
    </div>
  )
}

export default Awards