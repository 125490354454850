import React from 'react'

function Footer() {
  return (
    <div className='footer flex justify-center items-start bg-[#B8860B] py-5 text-white text-left' style={{minHeight: '400px', width: '100%'}}>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>About Us</p>
        </div>
        <div>
          <p>We are a non-profit organization dedicated to making a difference in the lives of young people. Founded in 2010, we have grown into a vibrant and dynamic community organization that works tirelessly to uplift and support youth from diverse backgrounds.</p>
        </div>
      </section>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>Contact Us</p>
        </div>
        <div className="flex flex-col w-full items-start text-left justify-between h-40">
          <p className='text-left'>Accra, Ghana</p>
 
          <p className='text-left'>+233541008285/+233533775699</p>
          <p className='text-left'>info@nanaapiatewafoundation.com</p>
        </div>
      </section>
    </div>
  )
}

export default Footer